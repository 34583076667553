@use 'sass:math';
@import '../../styles/utils.scss';

.el {
  --loading-padding: 65px;

  .loading {
    width: var(--loading-padding);
    height: var(--loading-padding);
    position: absolute;
    top: calc(100% - var(--loading-padding) - 5px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  
  .container {
    padding-bottom: 60px;
    max-width: $container-md;
    overflow: auto;
  }

  .filters {
    margin-top: 80px;
    @include tablet {
      margin-top: 0;
    }
  }

  .filterSelectWrap {
    flex-basis: 0;
    flex: 1 1 0px;
    & + .filterSelectWrap {
      button {
        border-left: none;
      }
    }
  }

  .searchBox {
    width: 100%;
    height: 100%;
    padding: 19px 20px 19px 32px;
    border: 1px solid $color-blue-25;
    border-radius: 0;
    color: $color-blue;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.03em;

    @include desktop {
      font-size: 14px;
    }
    @include tablet {
      border-top: none;
      border-left: none;
      border-right: none;
      padding: 19px $mobile-padding 19px;
      font-size: 18px;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  .disclaimers {
    margin-top: 10px;
  }

  .disclaimer {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
  }

  .filtersMobile {
    display: none;
    justify-content: space-between;
    padding: 20px $mobile-padding;
    margin-left: -$mobile-padding;
    margin-right: -$mobile-padding;
    border-bottom: 1px solid $color-blue-25;

    @include tablet {
      display: flex;
    }

    button {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      color: $color-blue;

      span {
        font-size: 24px;
        margin-right: 10px;
        margin-bottom: 5px;
        &:before {
          display: block;
          margin-bottom: -4px;
        }
      }
    }
  }

  .filterHeadMobile {
    display: none;
    height: 60px;
    border-bottom: 1px solid $color-blue-25;
    padding: 0 $mobile-padding;
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 200;
      background: #fff;
    }

    h3 {
      line-height: 1.1;
    }

    .backBtn {
      font-size: 24px;
      margin-top: 8px;
      span {
        display: inline-block;
        transform: scaleX(-1);
      }
    }

    .closeBtn {
      font-size: 24px;
      margin-top: 8px;
    }
  }

  .filterBottomMobile {
    display: none;
    position: fixed;
    z-index: 101;
    background: #fff;
    width: 100%;
    left: 100%;
    bottom: 0;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 17vh;
    transition: left 0.5s;
    @include tablet {
      display: flex;
    }

    .applyBtn {
      background: $color-orange;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: #fff;
      padding: 15px 30px;
      min-width: 230px;
      border-radius: 24px;
      margin-bottom: 10px;
    }
  }

  .filtersWrapper {
    display: flex;

    @include tablet {
      // display: none;
      left: 100%;
      display: block;
      position: fixed;
      overflow: auto;
      z-index: 100;
      width: 100%;
      height: calc(100vh - 60px);
      // height: calc(var(--vh, 1vh) * 100 - 60px);
      background: #fff;
      top: 60px;
      padding: 0 0 calc(17vh + 60px) 0;

      @supports (-webkit-touch-callout: none) {
        padding: 0 0 calc(17vh + 112px) 0;
      }
      transition: left 0.5s;

      & > div {
        width: 100% !important;
        max-width: 100vw;
      }

      &.open {
        left: 0;
        .filterBottomMobile {
          left: 0;
        }
      }
    }
  }

  .selectedFilters {
    .filterButton {
      position: relative;
      background: $color-orange;
      color: #fff;
      border-radius: 19px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      padding: 10px 51px 10px 20px;
      display: inline-flex;
      justify-content: space-between;
      text-transform: uppercase;
      margin-right: 20px;
      margin-bottom: 10px;

      span.cross {
        position: absolute;
        right: 15px;
        top: 17px;
        &:before, &:after {
          background: #fff;
        }
      }
    }

    &:global(.mobile) {
      display: none;
      padding: 12px $mobile-padding;
      background: $color-grey;
      width: 100%;
    }
    @include tablet {
      &:global(.desktop) {
        display: none;
      }
      &:global(.mobile) {
        display: block;
      }
    }
  }

  .filtersSort {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 26px 0 24px;
    @include tablet {
      // justify-content: flex-end;
      // padding: 40px 0 20px;
      align-items: center;
    }

    .sortSelect {
      width: 100%;
      min-width: 140px;
      border: 1px solid #C9C9D7;
      @include mobile {
        min-width: 110px;
        width: auto;
      }
    }
    & + .numberTeamMembers {
      margin-top: 0;
      @include tablet {
        margin-top: 0;
      }
    }
  }
  .numberTeamMembers {
    margin-top: 25px;
    padding-bottom: 23px;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tablet {
      margin-top: 40px;
      justify-content: space-between;
    }

    .number {
      text-decoration: underline;
    }

    .clearButton {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: $color-blue;
      text-transform: uppercase;
      white-space: nowrap;
      @include tablet {
        &:global(.desktop) {
          display: none;
        }
      }
      span {
        display: block;
        font-size: 24px;
        line-height: 16px;
        // margin-bottom: -2px;
        margin-right: 10px;
      }
    }

    &Mobile {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      padding-right: 15px;
      .number {
        text-decoration: underline;
      }
    }
  }

  .peopleWrapper {
    position: relative;
    // padding-bottom: var(--loading-padding);

    .masonryLayout {
      $expectedNumberPeople: 500;
      display: grid;
      grid-auto-rows: auto 40px 40px 40px auto 40px;
      overflow: hidden !important;
      padding-bottom: 75px;

      > * {
        > a {
          display: block;
        }
      }

      // Tiny, pattern of 2
      @media (max-width: 449px) {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 20px;
      }
      
      // Small, pattern of 4
      @media (min-width: 450px) {
        grid-template-columns: 1fr 40px 1fr;

        @for $i from 0 to (ceil(math.div($expectedNumberPeople, 4))) {
          > :nth-child(#{$i * 4 + 1}) {
            grid-row: #{1 + ($i * 6)} / #{4 + ($i * 6)};
            grid-column: 1;
          }
          > :nth-child(#{$i * 4 + 2}) {
            grid-row: 1 + ($i * 6);
            grid-column: 3;
          }
          > :nth-child(#{$i * 4 + 3}) {
            grid-row: 5 + ($i * 6);
            grid-column: 1;
          }
          > :nth-child(#{$i * 4 + 4}) {
            grid-row: #{3 + ($i * 6)} / #{6 + ($i * 6)};
            grid-column: 3;
          }
        }
      }

      // Mid, pattern of 6
      @media (min-width: 700px) {
        grid-template-columns: 1fr 40px 1fr 40px 1fr;

        @for $i from 0 to (ceil(math.div($expectedNumberPeople, 6))) {
          > :nth-child(#{$i * 6 + 1}) {
            grid-row: 1 + ($i * 6);
            grid-column: 1;
          }
          > :nth-child(#{$i * 6 + 2}) {
            grid-row: #{1 + ($i * 6)} / #{4 + ($i * 6)};
            grid-column: 3;
          }
          > :nth-child(#{$i * 6 + 3}) {
            grid-row: 1 + ($i * 6);
            grid-column: 5;
          }
          > :nth-child(#{$i * 6 + 4}) {
            grid-row: #{3 + ($i * 6)} / #{6 + ($i * 6)};
            grid-column: 1;
          }
          > :nth-child(#{$i * 6 + 5}) {
            grid-row: 5 + ($i * 6);
            grid-column: 3;
          }
          > :nth-child(#{$i * 6 + 6}) {
            grid-row: #{3 + ($i * 6)} / #{6 + ($i * 6)};
            grid-column: 5;
          }
        }
      }

      // Large, pattern of 8
      @media (min-width: 1250px) {
        grid-template-columns: 1fr 40px 1fr 40px 1fr 40px 1fr;

        @for $i from 0 to (ceil(math.div($expectedNumberPeople, 8))) {
          > :nth-child(#{$i * 8 + 1}) {
            grid-row: 1 + ($i * 6);
            grid-column: 1;
          }
          > :nth-child(#{$i * 8 + 2}) {
            grid-row: #{1 + ($i * 6)} / #{4 + ($i * 6)};
            grid-column: 3;
          }
          > :nth-child(#{$i * 8 + 3}) {
            grid-row: 1 + ($i * 6);
            grid-column: 5;
          }
          > :nth-child(#{$i * 8 + 4}) {
            grid-row: #{1 + ($i * 6)} / #{4 + ($i * 6)};
            grid-column: 7;
          }
          > :nth-child(#{$i * 8 + 5}) {
            grid-row: #{3 + ($i * 6)} / #{6 + ($i * 6)};
            grid-column: 1;
          }
          > :nth-child(#{$i * 8 + 6}) {
            grid-row: 5 + ($i * 6);
            grid-column: 3;
          }
          > :nth-child(#{$i * 8 + 7}) {
            grid-row: #{3 + ($i * 6)} / #{6 + ($i * 6)};
            grid-column: 5;
          }
          > :nth-child(#{$i * 8 + 8}) {
            grid-row: 5 + ($i * 6);
            grid-column: 7;
          }
        }
      }
    }
  }

  span.cross {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 12px;
    height: 2px;

    &:before, &:after {
      content: ' ';
      display: block;
      width: 12px;
      height: 2px;
      background: #fff;
      position: absolute;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  .peopleCard {
    display: inline-block;
    vertical-align: top;
    margin: 0 !important;
    width: 100%;
  }

  .noResults {
    padding: 50px 18px 100px;
    font-size: 36px;
  }

  :global(.mobile) {
    display: none;
  }

  @include tablet {
    :global(.desktop) {
      display: none;
    }
    :global(.mobile) {
      display: block;
    }
  }
}

.iconClose{
  font-size:14px;
  color: $color-blue;
 font-weight: bold;
 line-height: 120%;
 vertical-align:middle;
 text-transform: uppercase;
}
