@import '../../styles/utils.scss';

.el {
  position: relative;
  width: 100%;
  aspect-ratio: 580/775;

  .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      image-rendering: -webkit-optimize-contrast;
    }

    &:before {
      content: ' ';
      display: inline-block;
      width: 100%;
      padding-top: 133.44%;
    }

    &Wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .contentContainer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: auto;
    min-height: 80px;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.7);
    transition: all 0.25s;

    .content {
      margin: 14px;

      .name,
      .title {
        transition: all 0.25s;
      }

      .name {
        color: $color-blue;
        @include mobile {
          line-height: 1.1;
        }
      }
      
      .title {
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: -0.03em;
        color: $color-blue;
        @include mobile {
          font-size: 14px;
        }
      }
    }
  }

  &[data-style='below'] {
    .contentContainer {
      bottom: auto;
      top: calc(100% - 1px);
      background-color: $color-blue;
      transform: translateY(calc(-100% + 80px));

      .title, .name {
        color: #fff;
      }
    }
  }

  &:hover {
    .contentContainer {
      background-color: rgba(255, 255, 255, 1);

      .title, .name {
        color: $color-blue;
      }
    }
  }
}
