@import '../../styles/utils.scss';

.el {
  width: 100%;
  position: relative;

  .button {
    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px 20px;
      height: 64px;
      color: $color-blue;

      @include mobile {
        padding: 11px 20px;
      }
      @supports (-webkit-touch-callout: none) {
        pointer-events: none;
      }
    }
    select {
      display: none;
      @supports (-webkit-touch-callout: none) {
        display: inherit;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: -0.03em;
    white-space: nowrap;

    @include tablet {
      font-size: 13px;
    }

    @include desktop {
      font-size: 13px;
    }
  }
  .icon {
    font-size: 12px;
    margin-left: 12px;
    margin-top: -2px;
    color: $color-blue;
    @include tablet {
      // padding: 0 6px;
    }
    &:before {
      display: block;
      transform: rotate(180deg);
    }
    &.open {
      &:before {
        transform: rotate(0);
      }
    }
  }

  .options {
    overflow: hidden;
    max-height: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    background: $color-blue;
    color: #fff;
    // transition: max-height 0.4s ease-in-out;
    transition: max-height 0.4s ease-in-out;
    @include tablet {
    }
    &.closed {
      animation: optionsClose 0.4s ease-in-out forwards;
      max-height: 0;
    }
    &.active {
      // max-height: 250px;
      // overflow-y: auto;
      max-height: 250px;
      animation: optionsOpen 0.4s ease-in-out forwards;
      border: 1px solid $color-blue-25;
    }
    &Wrap {
      // padding: 13px 0 3px;
    }
    .optionItem {
      padding: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 2.6;
      letter-spacing: 0.05em;
      text-align: left;
      color: #fff;
      &:hover {
        background: $color-blue-80;
      }
      &.active {
        .checkbox {
          &:before {
            border-color: #fff;
            background-color: #fff;
          }
          &:after {
            border-color: $color-blue;
          }
        }
      }
      button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 9px 24px;
        text-align: left;
        transition: all 0.2s ease-in-out;
        color: #fff;
        @include mobile {
          padding: 8px 16px;
        }
      }
      .label {
        margin-top: 8px;
      }
      .checkbox {
        display: flex;
        align-items: center;
        position: relative;
        &:before {
          content: "";
          display: inline-block;
          box-sizing: border-box;
          margin: 3px 20px 3px 0px;
          border: solid 1px; /* Safari */
          border-color: $color-blue-25;
          border-radius: 0;
          width: 24px;
          height: 24px;
          min-width: 24px;
          vertical-align: top;
          transition: border-color 0.2s, background-color 0.2s;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 3px;
          left: 1px;
          width: 10px;
          height: 5px;
          border: solid 2px transparent;
          border-right: none;
          border-top: none;
          transform: translate(6px, 7px) rotate(-45deg);
        }
      }
    }
  }

  /* Colour Variants */
  &.orange {
    .button button,
    .icon {
      color: $color-orange;
    }
  }
}

@keyframes optionsOpen {
  0% { overflow: hidden; }
  99% { overflow: hidden; }
  100% { overflow-y: auto; }
}
@keyframes optionsClose {
  0% { overflow: auto; }
  1% { overflow: hidden; }
  100% { overflow-y: hidden; }
}
