.el {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: all 0.5s;

    &.visible {
      opacity: 1;
    }
}
