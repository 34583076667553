@import '../../styles/utils.scss';

.el {
  width: 100%;
  position: relative;

  .button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 20px 19px 32px;
    border: 1px solid $color-blue-25;
    color: $color-blue;

    @include tablet {
      border-top: none;
      border-left: none;
      border-right: none;
      padding: 19px $mobile-padding 19px;
    }
  }
  
  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow:hidden;

    @include desktop {
      font-size:13px;
    }
    @include tablet {
      font-size:18px;
    }
  }

  .icon {
    @include tablet {
      padding: 0 6px;
    }
    &:before {
      display: block;
      transform: rotate(180deg);
    }
    &.open {
      &:before {
        transform: rotate(0);
      }
    }
  }
  .options {
    overflow: scroll;
    overflow-x: hidden;
    max-height: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    background: $color-blue;
    color: #fff;
    transition: max-height 0.4s ease-in-out;
    -webkit-overflow-scrolling: touch;
    @include tablet {
      position: relative;
      transition: max-height 0.4s ease-in-out;
    }
    &.closed {
      max-height: 0;
      animation: optionsClose 0.4s ease-in-out forwards;
      @include tablet {
        animation: none;
      }
    }
    &.active {
      max-height: 250px;
      // overflow-y: auto;
      animation: optionsOpen 0.4s ease-in-out forwards;
      @include tablet {
        animation: none;
        max-height: var(--var-mh);
      }
    }
    &Wrap {
      padding: 10px 24px;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding: 10px 12px;
      }
    }
    .optionItem {
      padding: 9px 0;
    }
  }
  .subOptions {
    padding-left: 24px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      padding-left: 12px;
    }
  }
}

@keyframes optionsOpen {
  0% { overflow: hidden; }
  99% { overflow: hidden; }
  100% { overflow: scroll; overflow-x: hidden; }
}
@keyframes optionsClose {
  0% { overflow: scroll; overflow-x: hidden; }
  1% { overflow: hidden; }
  100% { overflow: hidden; }
}
